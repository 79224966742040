import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { SolvTheme } from '@solvhealth/jigsaw';
import history from '../../core/history';
import { colors } from '../../constants/colors';
import { fontFamily } from '../../constants/text';
import { biggerThanOrEqualTo, smallerThanOrEqualTo } from '../../core/util/styledComponents';
import Button, { ButtonVariations } from '../../components/SolvPatternLibrary/Button';
import SolvLogo from '../../components/SolvPatternLibrary/SolvLogo';

const Root = styled.div``;

const Content = styled.div`
  box-sizing: border-box;
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${smallerThanOrEqualTo.tablet`
    padding: unset;
  `}
  ${biggerThanOrEqualTo.tablet`
    padding: 10vh 10vw;
  `} * {
    margin: 0 auto;
  }
`;

const GlobalStyles = createGlobalStyle`
  #app {
    height: 100%;
  }
`;

const Header = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-start;
  padding-left: 24px;
  border-bottom: 1px solid ${colors.granite};
`;

const Img = styled.img`
  ${smallerThanOrEqualTo.tablet`
    margin: 32px auto;
    height: fit-content;
  `}
`;

const Title = styled.div`
  font-family: ${fontFamily.medium};
  font-size: 2em;
  color: ${colors.crunchBerry};
  text-align: center;
  padding: 12px;
`;

const ErrorContent = styled.div`
  font-size: 1em;
  color: ${colors.blueMonday};
  text-align: center;
  padding: 12px 12px 48px;
`;

const goToHomepage = () => {
  history.push('/');
};

const NotFound404 = () => {
  const buttonVariant = useMediaQuery(`screen and (max-width: ${SolvTheme.breakpoints.sm})`)
    ? ButtonVariations.link
    : ButtonVariations.pinkPrimary;
  return (
    <Root>
      <Header>
        <SolvLogo />
      </Header>

      <Content>
        <GlobalStyles />

        <Img alt="lost man holding map" src="/images/homepage/404_man_with_map.png" />

        <Title>Oh no! We couldn't find what you're looking for!</Title>

        <ErrorContent>
          This content has been moved or deleted, or you have navigated to the wrong page.
        </ErrorContent>

        <Button onClick={goToHomepage} variation={buttonVariant} width="240px">
          Return to homepage
        </Button>
      </Content>
    </Root>
  );
};

export default NotFound404;
