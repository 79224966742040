/* eslint-disable react/prop-types */
import React from 'react';
import NotFound404 from './NotFound404';

export default () => ({
  chunks: ['404'],

  component: <NotFound404 />,
  status: 404,
  accountWrapper: {
    loginRequired: false,
    nonBlocking: true,
  },
});

if (module.hot) {
  module.hot.accept();
}
